import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import stick from "../../images/stick.webp"
import "../../components/prizes.css"
// images
import { StaticImage } from "gatsby-plugin-image"

const Iteam = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">ITEAM Prize</h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                Calling all manufacturing workers! The U.S. Department of Energy
                (DOE) Advanced Manufacturing Office (AMO) invites you to be
                recognized for your creative ideas that saved energy at your
                manufacturing plant. DOE will select up to 5 winners in each
                category of small-, medium-, and large-size manufacturers, for a
                total of up to 15 winners.
              </p>
              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/ITEAM/community">
                    <button type="button" className="btns">
                      Follow the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="eager"/>
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <h1 className="mac_h1">
            The ITEAM Prize – Driving Measurable Energy Savings at U.S.
            Manufacturing Plants
          </h1>
          <p className="pcol-fz">
            The Individuals Taking Energy Action in Manufacturing (ITEAM) Prize
            aims to recognize employees at small-, medium-, and large-size U.S.
            manufacturing facilities who not only recognize the importance of
            saving energy, but are making it happen, strengthening their
            company’s competitiveness and driving cost savings in the process.{" "}
          </p>
          <p className="pcol-fz">
            By sharing successful strategies and examples of leadership,
            manufacturers can drive energy-productivity improvements and
            accelerate the adoption of energy-efficient technologies. Through
            this prize competition, DOE is seeking to recognize leaders who are
            responsible for creative, specific, and innovative projects to drive
            measurable energy savings in their manufacturing plants.{" "}
          </p>
          <p class="pcol-fz">
            Applications were evaluated on demonstrated energy savings, with
            additional credit being given for successful strategies to engage
            other workers on the manufacturing floor. Beyond the cash prize, the
            winners will be recognized at the virtual{" "}
            <a href="https://world.aeecenter.org/">
              Association of Energy Engineers World Energy Conference and Expo
            </a>
            . To facilitate sharing of successful strategies and examples of
            leadership, winning ideas and practices are highlighted on the{" "}
            <a href="https://betterbuildingssolutioncenter.energy.gov/individuals-taking-energy-action-manufacturing-prize">
              Better Buildings Solutions Center
            </a>{" "}
            website for the benefit of U.S. manufacturing facilities nationwide.
          </p>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h1 className="mac_h1">
            Congratulations to the ITEAM Prize Winners!
          </h1>
          <p className="pcol-fz">
            The ITEAM Prize is now closed. Each winner will receive a cash prize
            of $5,000 and be recognized at the virtual Association of Energy
            Engineers World Energy Conference and Expo. Winning ideas and
            practices are highlighted on the{" "}
            <a
              href="https://betterbuildingssolutioncenter.energy.gov/individuals-taking-energy-action-manufacturing-prize"
              class="has-text-bright"
            >
              Better Buildings Solutions Center
            </a>{" "}
            website.
          </p>
          <h4>ITEAM Prize Winners</h4>
          <div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>
                    Entry Title{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th>Winner</th>
                  <th className="tds">Location</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Compress It. Compressed Air Challenge</td>
                  <td>Rochelle Samuel, Saint-Gobain</td>
                  <td className="tds">Wilmington, CA</td>
                </tr>
                <tr>
                  <td>Continuous Improvement Leads to Energy Culture</td>
                  <td>Jeff Feyen, Charter Steel</td>
                  <td className="tds">Saukville, WI</td>
                </tr>
                <tr>
                  <td>Do the World a favor be a energy saver</td>
                  <td>George Anglin, Toyota</td>
                  <td className="tds">San Antonio, TX</td>
                </tr>
                <tr>
                  <td>Energy as a Service with Regard to LED Lighting</td>
                  <td>David Turkes, Bentley Mills</td>
                  <td className="tds">City of Industry, CA</td>
                </tr>
                <tr>
                  <td>Improving With Age - Boiler Efficiency Improvement</td>
                  <td>Marcus Johnson, Eastman</td>
                  <td className="tds">Kingsport, TN</td>
                </tr>
                <tr>
                  <td>The Leak Stoppers - Jackson, MI</td>
                  <td>Nick Sayles, Saint-Gobain</td>
                  <td className="tds">Jackson, MI</td>
                </tr>
                <tr>
                  <td>Mike Rogers: ISO 50001 at 3M Cottage Grove</td>
                  <td>Mike Rogers, 3M</td>
                  <td className="tds">Cottage Grove, MN</td>
                </tr>
                <tr>
                  <td>Terry McMichael: Compressed Air Improvements</td>
                  <td>Terry McMichael, 3M</td>
                  <td className="tds">Cynthiana, KY</td>
                </tr>
              </tbody>
            </table>
          </div>
          <span style={{ padding: "5px" }}></span>
          <h4>Honorable Mention</h4>
          <div>
            <table class="table is-hoverable is-fullwidth set-table is-size-6">
              <thead>
                <tr>
                  <th>
                    Entry Title{" "}
                    <span class="is-size-7 has-text-weight-normal"></span>
                  </th>
                  <th>Team</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Induction Heated Smart Susceptor Blankets</td>
                  <td>
                    Andy Hopkins, John Henry, Marc Matsen, Chris Hottes, and
                    James Kestner; Boeing
                  </td>
                  <td>Garden Grove, CA</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <div>
            <h1 className="mac_h1">Important Dates</h1>
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>
                <b>Release of the Prize: </b>
                October 5, 2018
              </li>
              <li>
                <b>Window for Completion Date of Eligible Projects: </b>
                October 5, 2018, to May 15, 2020
              </li>
              <li>
                <b>Submission Entry Deadline: </b>
                May 15, 2020 at 11:59 PM ET
              </li>
              <li>
                <b>Winners Announced:</b>
                July 2020
              </li>
              <li>
                <b>Cash Awards Disbursed: </b>
                August 2020
              </li>
              <li>
                <b>Awardee Projects on Display:</b>
                September 2020
              </li>
            </ul>

            <p className="ps">
              Sign up for updates on our{" "}
              <a
                href="https://www.herox.com/ITEAM"
                onclick="gtag('event', 'action', {'event_category': 'navigated to herox', 'event_label': 'linked to erobot'})"
              >
                HeroX challenge page
              </a>
              .
            </p>
            <b class="is-size-7">
              All dates are subject to change including contest openings,
              deadlines, and announcements.
            </b>
          </div>
          <hr />
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h3 className="h3ps mac_h1">
            {" "}
            Solutions that help shape the future{" "}
          </h3>
          <p className="hps">
            Are you a thinker, entrepreneur, facility, or potential partner?
            Anyone with an innovative idea can help promote transformation by
            participating in the American-Made Challenges.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/ITEAM/community">
              <button type="button" className="btns">
                Ready for a Challenge?
              </button>
            </a>
          </div>
        </div>

        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default Iteam
